import React from 'react'
import styled from 'styled-components'
import { CustomersReviewBlock } from './CustomersReviewBlock'
import CustomersReviewData from '../Data/CustomersReviewData'

const AppCustomersReviewWapper = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  margin-left: 5px;
  /* margin: 0px 0px 0px 4px; */
  @media (max-width: 1920px) {
    padding: 3rem 2rem 1rem 2rem;
    margin-left: 20px;
  }
  @media (max-width: 1650px) {
    padding: 3rem 2rem 1rem 2rem;
    margin-left: 3px;
  }
  @media (max-width: 1090px) {
    padding: 1rem 1rem 1rem 1rem;
    margin-left: 10px;
  }
  @media (max-width: 770px) {
    padding: 1rem 1rem 1rem 1rem;
    margin-left: 5px;
  }
  @media (max-width: 430px) {
    padding: 1rem 1rem 1rem 0rem;
    margin-left: 10px;
  }
  .mainContain {
    max-width: 1920px;
    /* margin: auto; */
    /* margin-top: -52px; */
  }
  .InternalSection {
    padding: 42px 0px 0px 112px;
    /* margin-left: -32px; */
    width: 95vw;
    background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 42px;
    background-color: #f3f4f6;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-flow: wrap;
      padding: 42px 0px 0px 35px;
    }
  }
  .containHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .containHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .CustomersReviewPara {
    line-height: 34px;
    font-size: 18px;
    max-width: 950px;
    padding: 0px 35px 105px 35px;
    @media (max-width: 1391px) {
      max-width: 100vw;
      padding: 15px 75px 65px 0px;
    }
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
      font-size: 16px;
    }
  }
  .CustomersReviewImage {
    height: auto;
    width: 21%;
    @media (max-width: 1391px) {
      width: 60%;
    }
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .CustomersReviewImageCenter {
    border: 9px solid #34a1d5;
    border-radius: 220px;
    width: 95%;
    @media (max-width: 1391px) {
      border: 9px solid #34a1d5;
      border-radius: 220px;
      width: 50%;
      margin: auto;
    }
    @media (max-width: 1090px) {
      border: 9px solid #34a1d5;
      border-radius: 220px;
      width: 40%;
      margin-left: 60px;
    }
  }
  .paraSection {
    /* margin-top: 1%; */
    margin: auto;
  }
  .iconColor {
    color: #34a1d5;
    /* padding: 15px 15px 15px 0px; */
  }
  .buttonDisplay {
    display: none;
  }

  .CustomersReviewParaTwo {
    font-size: 24px;
    font-weight: bolder;
    margin-left: 42px;
    @media (max-width: 1090px) {
      padding-left: 39px;
    }
    @media (max-width: 480px) {
      padding-left: 0px;
    }
  }
  .CustomersReviewParaThree {
    font-size: 22px;
    margin-left: 18px;
    padding-bottom: 12px;
    @media (max-width: 1090px) {
      padding-left: 44px;
    }
    @media (max-width: 480px) {
      padding-left: 0px;
    }
  }
`

export const AppCustomersReview = () => {
  return (
    <AppCustomersReviewWapper>
      {CustomersReviewData.map((data, index) => {
        return (
          <div key={index}>
            <CustomersReviewBlock
              className="CustomerReviewMainSection"
              // Heading={data.Heading}
              paraOne={data.paraOne}
              ImagePath={data.ImagePath}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              altName={data.AltName}
              mainContain="mainContain"
              containHeading="containHeadingSmall"
              containHeadingBig="containHeadingBig"
              containParaOne="CustomersReviewPara"
              containParaTwo="CustomersReviewParaTwo"
              containParaThree="CustomersReviewParaThree"
              InternalSection="InternalSection"
              containImage="CustomersReviewImage"
              paraSection="paraSection"
              IconColorTwo="iconColor"
              IconColorThree="iconColor"
              IconColorOne="buttonDisplay"
              IconColorFour="buttonDisplay"
              IconColorFive="buttonDisplay"
              containParaFive="buttonDisplay"
              containParaFour="buttonDisplay"
              ButtonDisplay="buttonDisplay"
              allImage="CustomersReviewImageCenter"
            />
          </div>
        )
      })}
    </AppCustomersReviewWapper>
  )
}
